var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("c-table", {
        ref: "table",
        attrs: {
          title:
            _vm.hcfFacilityType.hcfFacilityTypeName +
            " " +
            _vm.$language("시설현황"),
          columns: _vm.facilityColumns,
          data: _vm.facilityData,
          gridHeight: _vm.gridHeight,
          editable: _vm.editable && !_vm.disabled,
          isExcelDown: false,
          filtering: false,
          isFullScreen: false,
          columnSetting: false,
        },
        on: { linkClick: _vm.linkClick },
      }),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }